import React, { useCallback, useState, useEffect } from 'react';
import { ExportOutlined, UsergroupAddOutlined, CheckCircleOutlined, MinusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Skeleton, Alert, Tabs, Col, Row, Input, Table, Typography, Tag, Space, Card, message  } from 'antd';
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom';
import AddTalentsToUserButton from './AddTalentsToUserButton';
import DeleteTalentsButton from './DeleteTalentsButton';
import AddTalentModal from './AddTalentModal';
import SetTalentInvisibleModal from './SetTalentInvisibleModal';
import { useQuery, useMutation } from '@apollo/client';
import { GET_TALENTS, GET_USERS } from '../../queries';
import { UPDATE_TALENT, DELETE_TALENTS_USER, ADD_TALENTS_USER } from '../../mutations';
import { authenticationService } from '../../Utilities/authenticationService';
import { CSVLink } from "react-csv";
import { EditableTalentName } from './EditableTalentName';
import { EditableTalentManagers } from './EditableTalentManagers';

// 1. Create a separate TalentAction component
const TalentAction = React.memo(({ talent }) => (
    <Space direction='vertical'>
        <Link to={{ pathname: `/dashboard/${talent.node.id}` }}>データを確認</Link>
        <Link to={{ pathname: `/keywordSearch/YahooNewsArticle/${talent.node.id}` }}>ラベルツールを確認</Link>
    </Space>
));

// 2. Keep ScrapeToggleButton as is, but memoize it
const ScrapeToggleButton = React.memo(({ talent, onToggle }) => {
    const tagText = talent.node.isScraped ? '稼働中' : '停止中';
    const tagColor = talent.node.isScraped ? 'success' : 'default';
    const tagIcon = talent.node.isScraped ? <CheckCircleOutlined /> : <MinusCircleOutlined />;
    const buttonText = talent.node.isScraped ? 'Stop' : 'Start';
    const buttonType = talent.node.isScraped ? 'default' : 'primary';
    const buttonDanger = talent.node.isScraped;
    
    return (
        <Space direction='horizontal'>
            <Tag icon={tagIcon} color={tagColor}>{tagText}</Tag>
            <Button 
                size="small" 
                onClick={() => onToggle(talent)}
                danger={buttonDanger}
                type={buttonType}>
                {buttonText}
            </Button>
        </Space>
    );
});

// 3. Modify createTalentRow to only handle data transformation, not JSX creation
const createTalentRow = (talent, handleToggleScrapeButton) => ({
    key: talent.node.name,
    name: talent.node.name,
    id: talent.node.id,
    manager: talent.node.users.map(user => `${user.lastName} ${user.firstName}`).join(', '),
    talentsUsers: talent.node.users,
    talent: talent, // Pass the entire talent object for components to use
    handleToggle: handleToggleScrapeButton // Pass the toggle handler
});

const TalentsTable = () => {
    const { path } = useRouteMatch();
    const [rows, setRows] = useState([]);
    const [ownTalents, setOwnTalents] = useState([]);
    const [newTalentCreated, setNewTalentCreated] = useState(false);
    const [talentDeleted, setTalentDeleted] = useState(false);
    const [activeTab, setActiveTab] = useState('list');
    const [selectedTalentIds, setSelectedTalentIds] = useState([]);
    const [showAddTalentModal, setShowAddTalentModal] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentTalentId, setCurrentTalentId] = useState(null);
    const [currentTalentName, setCurrentTalentName] = useState(''); // State to hold talent_name
    const { data, loading, refetch } = useQuery(GET_TALENTS, {
        variables: { isVisible: true },
        fetchPolicy: 'network-only',
    });
    const currentUserId = authenticationService.currentUserValue.id;

    const [updateTalent] = useMutation(UPDATE_TALENT, {
        onCompleted: () => {
            message.success('タレントが変更されました。');
            refetch(); // Refetch data to update the table
        },
        onError: (error) => {
            message.error('タレントを変更できませんでした。');
        }
    });

    const { data: usersData } = useQuery(GET_USERS, {
        onError: (error) => {
            console.log(error);
        }
    });

    const [addTalentsUser] = useMutation(ADD_TALENTS_USER, {
        onError: (error) => {
            console.log(error);
        },
        onCompleted: () => {
            refetch()
        }
    });

    const [deleteTalentsUser] = useMutation(DELETE_TALENTS_USER, {
        onError: (error) => {
            console.log(error);
        },
        onCompleted: () => {
            refetch()
        }
    });
    
    // Define the delete handler
    const handleVisible = (id) => {
        updateTalent({
            variables: {
                input: {
                    id,
                    isScraped: false,
                    isVisible: false // Set isVisible to false
                }
            }
        });
    };

    // Show modal
    const showDeleteModal = (id, name) => {
        console.log('showDeleteModal called with:', { id, name });
        setCurrentTalentId(id);
        setCurrentTalentName(name);
        setIsModalVisible(true);
    };

    // Confirm delete
    const handleDeleteConfirm = () => {
        if (currentTalentId) {
            handleVisible(currentTalentId); // Call the existing delete logic
        }
        setIsModalVisible(false);
        setCurrentTalentId(null);
        setCurrentTalentName('');
    };

    // Cancel delete
    const handleDeleteCancel = () => {
        setIsModalVisible(false);
        setCurrentTalentId(null);
        setCurrentTalentName('');
    };

    // 4. Modify columns to use the components directly
    const columns = [
        {
            title: <Typography.Text strong>タレント名</Typography.Text>,
            dataIndex: 'name',
            render: (text, record) => <EditableTalentName name={text} id={record.id} refetch={refetch} />
        },
        {
            title: <Typography.Text strong>マネージャー</Typography.Text>,
            dataIndex: 'manager',
            render: (text, record) => (
                <EditableTalentManagers 
                    talentsUsers={record.talentsUsers} 
                    users={usersData?.users} 
                    id={record.id} 
                    refetch={refetch} 
                    addTalentsUser={addTalentsUser} 
                    deleteTalentsUser={deleteTalentsUser} 
                />
            )
        },
        {
            title: <Typography.Text strong>スクレーパー稼働状況</Typography.Text>,
            dataIndex: 'isScraped',
            render: (_, record) => (
                <ScrapeToggleButton 
                    talent={record.talent} 
                    onToggle={record.handleToggle} 
                />
            )
        },
        {
            dataIndex: 'action',
            className: 'align-right',
            render: (_, record) => <TalentAction talent={record.talent} />
        },
        {
            dataIndex: 'action',
            className: 'align-center',
            width: 80,
            render: (_, record) => 
                activeTab === 'all' ? ( // Check if the activeTab is "all"
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <DeleteOutlined
                            style={{ color: '#1890ff', cursor: 'pointer', fontSize: '22.5px' }}
                            onClick={() => showDeleteModal(record.id, record.name)} // Show modal on click
                        />
                    </div>
                ) : null // Do not render anything if activeTab is not "all"
        }
    ];

    // Keep handleToggleScrapeButton as a useCallback
    const handleToggleScrapeButton = useCallback(
        (talent) => {
            const input = { id: talent.node.id, isScraped: !talent.node.isScraped };
            updateTalent({ variables: { input } });
        },
        [updateTalent]
    );

    const resetSearch = useCallback(
        (data) => {
            if (data) {
                const tableRows = data.talents.edges.map(talent => 
                    createTalentRow(talent, handleToggleScrapeButton)
                );
                setRows(tableRows);
            }
        },
        [handleToggleScrapeButton]
    );

    useEffect(() => {
        resetSearch(data);
        if (data) {
            const ownedTalents = data.talents.edges
                .filter(talent => talent.node.users.find(user => parseInt(user.id) === currentUserId))
                .map(talent => createTalentRow(talent, handleToggleScrapeButton));
            setOwnTalents(ownedTalents);
        }
    }, [resetSearch, data, currentUserId, handleToggleScrapeButton]);

    if (loading) {
        return <Skeleton />;
    }

    const handleSearch = searchField => {
        if (searchField.target.value === '') {
            resetSearch(data);
            const ownedTalents = data.talents.edges
                .filter(talent => talent.node.users.find(user => parseInt(user.id) === currentUserId))
                .map(talent => createTalentRow(talent, handleToggleScrapeButton));
            setOwnTalents(ownedTalents);
        } else {
            const searchValue = searchField.target.value;
            const filteredRows = data.talents.edges
                .filter(talent => talent.node.name.includes(searchValue))
                .map(talent => createTalentRow(talent, handleToggleScrapeButton));
            setRows(filteredRows);

            const filteredOwnTalents = data.talents.edges
                .filter(talent => 
                    talent.node.users.find(user => parseInt(user.id) === currentUserId) &&
                    talent.node.name.includes(searchValue)
                )
                .map(talent => createTalentRow(talent, handleToggleScrapeButton));
            setOwnTalents(filteredOwnTalents);
        }
    };

    const showModal = () => {
        setShowAddTalentModal(true);
    };

    const hideModal = () => {
        setShowAddTalentModal(false);
    };

    const CSVdata = activeTab === 'all' ?
        [['ID', 'タレント名', 'マネージャー']].concat(rows.map(row => [row.id, row.name, row.manager])) :
        [['ID', 'タレント名', 'マネージャー']].concat(ownTalents.map(row => [row.id, row.name, row.manager]));

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log('selectedRows: ', selectedRows);
            setSelectedTalentIds(selectedRows.map(row => row.id));
        }
    };

    const TableHeader = <>
        {
            newTalentCreated &&
                <>
                    <Row>
                        <Col span={24}>
                            <Alert message="担当リストに追加しました。" type="success" showIcon closable/>
                        </Col>
                    </Row>
                    <br/>
                </>
        }
        {
            talentDeleted &&
                <>
                    <Row>
                        <Col span={24}>
                            <Alert message="担当リストから削除しました。" type="success" showIcon closable/>
                        </Col>
                    </Row>
                    <br/>
                </>
        }
        <Row>
            <Col span={4}>
                <Input.Search onChange={handleSearch} placeholder="検索" allowClear />
            </Col>
            <Col span={20}>
                <Row gutter={[16, 0]} type="flex" justify="end">
                    <Col>
                        { activeTab === 'all' &&
                            <Button 
                                icon={<UsergroupAddOutlined />} 
                                type='primary'
                                onClick={showModal}
                            >
                                タレントの新規追加
                            </Button>
                        }
                    </Col>
                    <Col>
                        { activeTab === 'all' &&
                            <AddTalentsToUserButton
                                selectedTalentIds={selectedTalentIds}
                                handleCompleted={() => { refetch(); setNewTalentCreated(true); } }
                            />
                        }
                    </Col>
                    <Col>
                        { activeTab === 'list' &&
                            <DeleteTalentsButton
                                selectedTalentIds={selectedTalentIds}
                                handleCompleted={ () => { refetch(); setTalentDeleted(true); } }
                            />
                        }   
                    </Col>
                    <Col>
                        <CSVLink data={CSVdata} filename={'タレント.csv'}>
                            <Button icon={<ExportOutlined />}>
                                CSV 出力
                            </Button>
                        </CSVLink>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>;

    return (
    <>
        <SetTalentInvisibleModal
            visible={isModalVisible}
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
            talentName={currentTalentName}
        />

        <Switch>
            <Route exact path={path}>
                <Space style={{ width: '100%' }} direction='vertical'>
                    <Row>
                        <Col span={24}>
                            <Card title={<Typography.Title level={3} style = {{ background: '#fff' }}>タレント一覧</Typography.Title>}>
                                <Route exact path={path}>
                                    <Tabs onChange={(activeTab) => setActiveTab(activeTab) } defaultActiveKey="list">
                                        <Tabs.TabPane tab="担当リスト" key="list">
                                            <Table
                                                scroll={{
                                                    y: 500,
                                                }}
                                                columns={columns}
                                                dataSource={ownTalents}
                                                rowSelection={{ type: 'checkbox', ...rowSelection }}
                                                title={() => TableHeader}
                                            />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab="全てのリスト" key="all">
                                            <Table
                                                scroll={{
                                                    y: 500,
                                                }}
                                                columns={columns}
                                                dataSource={rows}
                                                rowSelection={{ type: 'checkbox', ...rowSelection }}
                                                title={() => TableHeader}
                                            />
                                        </Tabs.TabPane>
                                    </Tabs>
                                    { showAddTalentModal &&
                                        <AddTalentModal 
                                            handleCompleted={() => { refetch(); message.success('全てのリストに追加しました。', 4); }}
                                            visible={showAddTalentModal}
                                            hideModal={hideModal}
                                        />
                                    }
                                </Route>
                            </Card>
                        </Col>
                    </Row>
                </Space>
            </Route>
        </Switch>;
    </>
);};

export default TalentsTable;
