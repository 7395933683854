import React, { useState, useEffect } from 'react';
import { Card, Form, Typography, Select, DatePicker, Row, Col, Spin, Layout } from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { GET_TALENTS } from '../../queries';
import { useQuery } from '@apollo/client';
import * as moment from 'moment';
import AnalyzeComments from './analyzeComments';

const { Content } = Layout;

const ChatGPT = () => {
    const savedFormParams = localStorage.keywordSearch && JSON.parse(localStorage.keywordSearch);
    const { data: names, loading: loadingNames } = useQuery(GET_TALENTS, { variables: { isVisible: true } });

    const [form] = Form.useForm();
    const [talentId, setTalentId] = useState(savedFormParams?.selectedNameId || null);
    const [talentName, setTalentName] = useState('');

    // 🟢 Update `talentName` when `talentId` or `names` data changes
    useEffect(() => {
        if (names && talentId) {
            const selectedTalent = names.talents.edges.find(talent => talent.node.id === talentId);
            setTalentName(selectedTalent?.node.name || '');
        }
    }, [names, talentId]); // Runs whenever `names` or `talentId` changes

    const handleValuesChange = (changedValues, allValues) => {
        if (allValues.talentname) {
            const selectedTalent = names?.talents?.edges?.find(talent => talent.node.id === allValues.talentname);
            setTalentId(allValues.talentname);
            setTalentName(selectedTalent?.node.name || '');
        }
    };

    return (
        <Layout style={{ minHeight: '90vh', display: 'flex', flexDirection: 'column'}}>
            <Content style={{ padding: '0 0', background: '#fff', flex: '0 0', height: '15%'}}>
                <Card title={<Typography.Title level={3}>タレント・期間設定</Typography.Title>}>
                    <Form
                        form={form}
                        layout="inline"
                        size="large"
                        initialValues={{
                            date: [moment().subtract(1, 'months'), moment()],
                            keyword: savedFormParams?.keyword || '',
                            talentname: savedFormParams?.selectedNameId || ''
                        }}
                        onValuesChange={handleValuesChange} // Track changes to form values
                    >
                        <Row gutter={[16, 8]}>
                            <Col>
                                <Form.Item name="talentname" label="タレント名">
                                    <Select
                                        showSearch
                                        placeholder="選択してください"
                                        style={{ width: 200 }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {!loadingNames &&
                                            names?.talents?.edges?.map((talent) => (
                                                <Select.Option key={talent.node.id} value={talent.node.id}>
                                                    {talent.node.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="date" label="日付" rules={[{ required: true, message: '日付を入力してください' }]}>
                                    <DatePicker.RangePicker locale={locale} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Content>

            {/* OpenAI Summary Comment Section */}
            <Content style={{ flex: 1, padding: '16px 0', overflow: 'auto', display: 'flex', flexDirection: 'column', height: '85%'}}>
                <Card
                    title={<Typography.Title level={3}>{talentName}さんに関する質問</Typography.Title>}
                    style={{ flex: 1, overflow: 'auto' }}
                >
                    {loadingNames ? (
                        <Spin />
                    ) : (
                        <AnalyzeComments
                            key={talentId}
                            talentId={talentId}
                            talentName={talentName}
                        />
                    )}
                </Card>
            </Content>
        </Layout>
    );
};

export default ChatGPT;
