import React from 'react';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Typography, Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import html2pdf from 'html2pdf.js';
import ReactDOM from 'react-dom';

const { Paragraph } = Typography;

/**
 * Clean and Validate Markdown Before Rendering
 */
const cleanMarkdown = (markdown) => {
    if (!markdown) return '';

    try {
        let cleaned = markdown
            .trim()
            .replace(/\r\n/g, '\n')  // Normalize newlines
            .replace(/\u200B/g, '')  // Remove zero-width spaces
            .replace(/(\d),(\d)/g, '$1$2')  // Remove commas inside numbers (prevents split errors)
            .replace(/\|\s*\n/g, '| \n')  // Fix empty table rows
            .replace(/(\|[^\n]*)\n(\|[-]+)/g, '$1\n$2') // Fix header separators
            .replace(/talent_id:\s*\d+/g, ''); // Remove talent_id: {number}

        return cleaned;
    } catch (error) {
        console.error("❌ Error cleaning Markdown:", error);
        return markdown;
    }
};

/**
 * Convert Markdown to PDF and Trigger Download
 */
const downloadPDF = async (markdown) => {
    // Create a temporary container
    const container = document.createElement('div');
    container.style.padding = '20px';
    container.style.fontFamily = 'Arial, sans-serif';
    container.style.width = '755px';
    container.style.maxWidth = '755px';
    container.style.wordBreak = 'break-word';
    document.body.appendChild(container);

    // Render the ReactMarkdown component using ReactDOM.render
    ReactDOM.render(
        <div style={{ padding: '20px' }}>
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                    p: ({ children }) => <Paragraph>{children}</Paragraph>,
                    a: ({ href, children }) => <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>,
                    table: ({ children }) => (
                        <div style={{
                            overflowX: 'auto',
                            maxWidth: '100%', 
                            padding: "10px",
                            border: "1px solid #ddd",
                            borderRadius: "8px"
                        }}>
                            <table className="gfm-table" style={{ width: "100%", minWidth: "755px", tableLayout: "fixed" }}>
                                {children}
                            </table>
                        </div>
                    ),
                    th: ({ children }) => <th style={{ background: "#f0f0f0", padding: "8px", textAlign: "left" }}>{children}</th>,
                    td: ({ children }) => <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{children}</td>,
                }}
            >
                {markdown}
            </ReactMarkdown>
        </div>,
        container
    );

    // Wait for the rendering to complete
    await new Promise(resolve => setTimeout(resolve, 100));

    // Configure PDF options
    const opt = {
        margin: [0.2, 0.2, 0.2, 0.2],  // [top, right, bottom, left] in inches
        filename: 'report.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { 
            scale: 2,
            useCORS: true,
            logging: false,
            letterRendering: true,
            scrollY: 0,
            windowWidth: 1200,  // Increased from 1024
            windowHeight: 768
        },
        jsPDF: { 
            unit: 'in', 
            format: 'a4', 
            orientation: 'portrait',
            compress: true
        }
    };

    // Generate PDF
    await html2pdf().set(opt).from(container).save();

    // Clean up
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
};

const MarkdownRenderer = ({ content, index }) => {
    const sanitizedContent = cleanMarkdown(content);
    const showPDFButton = index !== undefined && index % 2 === 1; // Show button for odd indices
    
    return (
        <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                    p: ({ children }) => <Paragraph>{children}</Paragraph>,
                    a: ({ href, children }) => <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>,
                    table: ({ children }) => (
                        <div style={{
                            overflowX: 'auto',
                            maxWidth: '100%', 
                            padding: "10px",
                            border: "1px solid #ddd",
                            borderRadius: "8px"
                        }}>
                            <table className="gfm-table" style={{ width: "100%", minWidth: "755px", tableLayout: "fixed" }}>
                                {children}
                            </table>
                        </div>
                    ),
                    th: ({ children }) => <th style={{ background: "#f0f0f0", padding: "8px", textAlign: "left" }}>{children}</th>,
                    td: ({ children }) => <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{children}</td>,
                }}
            >
                {sanitizedContent}
            </ReactMarkdown>
            {sanitizedContent && showPDFButton && (
                <Button 
                    type="text"
                    icon={<FilePdfOutlined style={{ fontSize: '18px', color: '#1890ff' }} />}
                    onClick={() => downloadPDF(sanitizedContent)} 
                    style={{ 
                        marginTop: "10px",
                        color: '#1890ff'
                    }}
                >
                    PDFにダウンロード
                </Button>
            )}
        </div>
    );
};

export default MarkdownRenderer;