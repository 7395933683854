import React, { useState, useEffect } from 'react';
import { GRAVITY_API_ROOT } from '../../constants';
import { authenticationService } from '../../Utilities/authenticationService';
import { Input, Button, Divider } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import chatgptIcon from '../../static/chatgpt-icon.png';
import MarkdownRenderer from './MarkdownRenderer';
import { useQuery } from '@apollo/client';
import { GET_CHAT_INTERACTION } from '../../queries';

const AnalyzeComments = ({talentId, talentName}) => {
    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [conversationHistory, setConversationHistory] = useState([]);
    const [chatInteractionId, setChatInteractionId] = useState(null);
    const [pollingEnabled, setPollingEnabled] = useState(false);

    const { data: chatData, startPolling, stopPolling } = useQuery(GET_CHAT_INTERACTION, {
        variables: { chatInteractionId },
        skip: !chatInteractionId,
        pollInterval: 0, // We'll control polling manually
    });

    useEffect(() => {
        if (pollingEnabled && chatInteractionId) {
            startPolling(5000); // Poll every 5 seconds
        }
        return () => stopPolling();
    }, [pollingEnabled, chatInteractionId, startPolling, stopPolling]);

    useEffect(() => {
        if (chatData?.getChatInteraction?.response) {
            // We got a response, stop polling
            setPollingEnabled(false);
            stopPolling();
            
            // Update conversation history with the response
            setConversationHistory(prev => [
                ...prev,
                { type: 'human', content: chatData.getChatInteraction.response }
            ]);
            setLoading(false);
            setQuestion('');
        }
    }, [chatData, stopPolling]);

    const handleSearch = () => {
        setLoading(true);

        const formattedQuestion = `${talentName} さんについての次の質問を答えてください。${question}`;

        const messages = conversationHistory.length === 0
        ? [
            { content: `${formattedQuestion} talent_id: ${talentId}`, type: 'human' }
          ] : [
            ...conversationHistory,
            { content: `${formattedQuestion} talent_id: ${talentId}`, type: 'human',  }
          ];

        fetch(`${GRAVITY_API_ROOT}/chatgpt/chat_analysis`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${authenticationService.currentUserValue.token}`,
                'Content-Type' : 'application/json',
            },
            body : JSON.stringify({
                input: {
                    messages: messages,
                    next: "supervisor",
                },
                config: {}
            }),
        })
        .then(response => {
            return response.json(); // Parse JSON from response body
        })
        .then(data => {
            // Update conversation history with the user's question
            setConversationHistory([...messages]);
            // Start polling if we got an interaction ID
            if (data?.chat_interaction_id) {
                setChatInteractionId(data.chat_interaction_id);
                setPollingEnabled(true);
            } else {    
                setLoading(false);
                console.error("No chat interaction ID received");
            }
        })
        .catch(error => {
            console.error("Error during fetch:", error);
            setLoading(false);
        });
    }

    const renderContent = (message, index) => {
    
        return (
            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '16px' }}>
                {/* Conditional rendering for icons */}
                {index % 2 === 0 ? (
                    <div
                        style={{
                            width: '80px',
                            height: '80px',
                            marginRight: '12px',
                        }}
                    />
                ) : (
                    <img
                        src={chatgptIcon}
                        alt="ChatGPT Icon"
                        style={{
                            width: '80px',
                            height: '80px',
                            marginRight: '12px',
                            borderRadius: '50%',
                        }}
                    />
                )}
                <div style={{ maxWidth: '755px', wordBreak: 'break-word' }}>
                    <MarkdownRenderer content={message.content} index={index} />
                </div>
            </div>
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '16px' }}>
            <div style={{ display: 'flex', marginBottom: '16px' }}>
                <Input
                    placeholder="質問を入力してください (例: 〇〇の演技についての評価を教えてください)"
                    style={{ width: '80%', marginRight: '1%'}}
                    value={ question }
                    onChange={(e) => setQuestion(e.target.value)}
                />
                <Button 
                    type="primary" 
                    onClick={handleSearch} 
                    style={{ width: '10%', marginRight: '1%' }} 
                    loading={loading} 
                    disabled={question === ''}
                >
                    質問する
                </Button>
                <Button type="primary"
                        onClick={() => setConversationHistory([])}
                        style={{ width: '8%'}}
                        icon={<DeleteOutlined/>} 
                        disabled={conversationHistory.length === 0}
                >
                        消去
                </Button>
            </div>
            <div>
                {conversationHistory.map((message, index) => (
                    <React.Fragment key={index}>
                        {renderContent(message, index)}
                        <Divider />
                    </React.Fragment>
                ))}
                {loading && (
                    <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '16px' }}>
                        <img
                            src={chatgptIcon}
                            alt="ChatGPT Icon"
                            style={{
                                width: '80px',
                                height: '80px',
                                marginRight: '12px',
                                borderRadius: '50%',
                            }}
                        />
                        <div style={{ 
                            maxWidth: '600px', 
                            wordBreak: 'break-word',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#666',
                            fontSize: '16px'
                        }}>
                            ChatGPT実行中...
                        </div>
                    </div>
                )}
            </div>
        </div>
      );
} 
 
export default AnalyzeComments